.App {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}
.MuiButtonBase-root {
    text-transform: none!important;
}
.error {
    color: red;
}

.headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 95%;
    max-width: 1100px;
    padding-top: 40px
}
.walletContainer {
    flex: 3;
    min-width: 300px;
    & > div {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
    }
    label {
        margin-right: 10px;
    }
    select {
        width: 100%;
        max-width: 350px;
    }
}
.inputContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 1;
    min-width: 300px;
    & select {
        width: 177px;
    }
    & > div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 4px 0px;
        label {
            margin-right: 10px;
        }
    }
}
.balanceContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 0;
    h5 {
        margin: 0px 20px;
    }
}
.positionContainer {
    width: 90%;
    border-top: 1px solid white;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
}
.positionRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 10px;
    border: 1px solid rgb(90, 90, 90);
    margin: 8px 0px;
    border-radius: 4px;
    & > div:nth-child(1) {
        margin-right: 20px;
        width: 100px;
    }
    .liquidity {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex: 1;
        padding: 0px 20px;
        & > label {
            border-bottom: 1px solid rgb(73, 73, 73);
            padding-bottom: 8px;
        }
        & > div {
            padding: 4px;
            display: flex;
            flex-direction: row;
            flex: 1;
            justify-content: space-around;
            & > div {
                display: flex;
                flex-direction: column;
                width: 40%
            }
        }
    }
    & > div:nth-child(4) {
        margin-right: 20px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
}
.status {
    color: rgb(8, 253, 0);
}
.row {
    display: flex;
    flex-direction: row;
}
.col {
    display: flex;
    flex-direction: column;
}
.borderBox {
    border: 1px solid white;
    border-radius: 4px;
    margin: 10px;
    padding: 10px 20px;
}

.footer {
    height: 40px;
    label {
        margin: auto 20px;
    }
}